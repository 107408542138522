import React from "react";
import './PostCard.css'
import {Link} from 'react-router-dom';
import {Card,Divider} from "antd";
import {Avatar,Paper,Button,IconButton,Badge} from "@material-ui/core";
import {Comment,Favorite,Share} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import VisibilitySensor from "react-visibility-sensor";
import EntryAnim from "../../../utils/EntryAnim";
import moment from "moment";
import {generateIframe} from "../utils";
import {deletePost,createComment,createReply} from "../Service";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ScrollBars from 'react-custom-scrollbars';
import TextField from "@material-ui/core/TextField";
const FollowButton = withStyles((theme) => ({
    root: {
        color: '#e05050',
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#e0505020',
        },
        borderColor:'#e05050',
        borderWidth:2,
        display:'flex',
        height:32,
        width:'20%',
    },
}))(Button);

export default class PostCard extends React.Component{
    constructor(props) {
        super(props);
        this.avatar = require('../../../assets/img/defaultAvatar.jpg');
        this.videoSrc = require('../../../assets/video/testvideo.mp4');
        this.state={
            isLiked:false,
            videoInViewport:false,
            menuAnchor:null,
            showComments:false,
            comments:props.data?.comments,
            replyTo:-1,
        }
    }

    renderComments(data){
        const {Meta} = Card;
        let output = [];
        try{
            output = data.map((comment,index)=>(
                <Card style={{width: '100%'}} className="neighblog-card">
                    <div style={{display:"flex",justifyContent:'space-between'}}>
                        <Meta
                            avatar={<Avatar src={this.avatar} />}
                            title={comment.user.username}
                            description={moment(comment.createdAt).format('M-D HH:mm')}
                        />
                        {this.props.user?(
                            <FollowButton
                                style={{marginRight:24,width:64,height:36,display:'flex',alignItems:'center',justifyContent:'center',zIndex:10}}
                                onClick={()=>{this.setState({replyTo:comment.id})}}
                            >
                                回复</FollowButton>
                        ):(
                            null
                        )}
                    </div>
                    <div style={{padding: 4}}>{comment.body}</div>
                    {this.state.replyTo===comment.id?(
                        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginTop:12,padding:12}}>
                            <Avatar
                                style={{
                                    width:48,
                                    height:48,
                                    marginRight:24,
                                }}
                                alt="Remy Sharp"
                                src={this.avatar}
                            />
                            <TextField style={{flexGrow:1}} id="reply" multiline={true} rows={2} label="您的回复..." variant="outlined" />
                            <FollowButton
                                onClick={async ()=>{
                                    const body = document.getElementById('reply').value;
                                    const res = await createReply({body:body,postId:this.props.data.id,commentId:comment.id,repliedId:comment.user.id})
                                    if(res.success){
                                        const _comments = [...this.state.comments];
                                        _comments[index].replies.push({
                                            body:body,
                                            createdAt:moment(),
                                            id:res.data.id,
                                            user:this.props.user,
                                            userId:this.props.user.id,
                                        });
                                        this.setState({comments:_comments},()=>{document.getElementById('reply').value=null;this.setState({replyTo:-1})});
                                        alert('回复成功')
                                    }
                                }}
                                style={{marginLeft:24,width:96,height:48,backgroundColor:'#E05050',display:'flex',alignItems:'center',justifyContent:'center',zIndex:10}}>
                                <a style={{color:'whitesmoke',fontWeight:'600',fontSize:18}}>回复</a>
                            </FollowButton>
                        </div>
                    ):(null)}
                    {this.renderReplies(comment.replies)}
                </Card>
            ))
        }catch (e) {
            console.log(e);
        }
        if(!output[0]){
            output.push(
                <Card style={{width: '100%',height:200,display:'flex',alignItems:'center',justifyContent:'center'}} className="neighblog-card">
                    <text style={{fontSize:20,color:"gray"}}>暂无评论</text>
                </Card>,
            )
        }
        return output;
    }
    renderReplies(data){
        const {Meta} = Card;
        let output = [];
        for (const reply of data) {
            output.push(
                <Meta
                    avatar={<Avatar src={this.avatar} />}
                    title={reply.user.username}
                    description={moment(reply.createdAt).format('M-D HH:mm')}
                />,
                <div style={{padding: 4}}>{reply.body}</div>,
                <Divider style={{margin: 4}} />,
            );
        }
        if (data[0]) {
            return (
                <Card style={{width: '100%'}} className="neighblog-card">
                    {output}
                    {/*<Button type="text" style={{width: '100%'}}>*/}
                    {/*    加载更多*/}
                    {/*</Button>*/}
                </Card>
            );
        }
        return null;
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(
            this.state.isLiked!==nextState.isLiked||
            this.state.videoInViewport!==nextState.videoInViewport||
            this.state.menuAnchor!==nextState.menuAnchor||
            this.state.showComments!==nextState.showComments||
            this.state.comments!==nextState.comments||
            this.state.replyTo!==nextState.replyTo||
            this.props!==nextProps
        ){
            return true
        }
        return false
    }

    render() {
        return (
            <EntryAnim
                duration={'1s'}
                type={'fadeInLeft'}
                partialVisibility={true}
            >
                <Paper
                    elevation={2}
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                        //height:this.props.height,
                        backgroundColor:'white',
                        borderRadius:8,
                        padding:12,
                        margin:8,
                        paddingBottom:24,
                    }}
                >
                    <div style={{
                        display:'flex',
                        width:'100%',
                        justifyContent:'space-between',
                        alignItems:'center',
                    }}>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column',width:64,paddingTop:12}}>
                            <Avatar
                                style={{
                                    width:54,
                                    height:54,
                                    marginBottom:8,
                                }}
                                alt="Remy Sharp"
                                src={this.avatar}
                            />
                            <text style={{marginBottom:8,fontSize:14}}>
                                {this.props.data.user.username}
                            </text>
                        </div>
                        <div
                            style={{}}
                        >
                            <div
                                style={{
                                    display:'flex',
                                    flexDirection:'column',
                                    flexGrow:1,
                                }}
                            >
                                <text>
                                    <text  style={{
                                        fontSize:20,
                                        fontWeight:'bold',
                                        wordWrap:'break-word',
                                        overflow:'visible',
                                    }}>
                                        {this.props.data.title}
                                    </text>
                                </text>
                                <text>
                                    {moment().format('MM-DD-HH:mm')}
                                </text>
                            </div>

                        </div>
                        {/*<FollowButton*/}
                        {/*    variant={'outlined'}*/}
                        {/*>*/}
                        {/*    关注*/}
                        {/*</FollowButton>*/}
                        <div style={{width:64}}/>
                    </div>
                    <text style={{
                        fontSize: 18,
                        wordWrap: 'break-word',
                        overflow: 'auto',
                    }}>
                        {this.props.data.body}
                    </text>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        {                                generateIframe({
                            videoSource:this.props.data.videoSource,
                            videoUrl:this.props.data.videoUrl,
                            height:'auto',
                            width:window.innerWidth*0.62,

                        })}
                        {/*<video className={'video1'} controls preload={'metadata'}>*/}
                        {/*    <source src={this.videoSrc} type="video/mp4"/>*/}
                        {/*</video>*/}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 48
                            }}>
                            {/*<IconButton aria-label="点赞" style={{marginLeft: 12}}>*/}
                            {/*    <Badge color="secondary" badgeContent={'4.3K'} >*/}
                            {/*        <Favorite style={{height: 36, width: 36}}/>*/}
                            {/*    </Badge>*/}
                            {/*</IconButton>*/}
                            {/*<IconButton aria-label="点赞" style={{marginLeft: 12}}>*/}
                            {/*    <Badge color="secondary" badgeContent={27} max={99}>*/}
                            {/*        <Comment style={{height: 36, width: 36}}/>*/}
                            {/*    </Badge>*/}
                            {/*</IconButton>*/}
                            {/*<IconButton aria-label="点赞" style={{marginLeft: 12}}>*/}
                            {/*    <Badge color="secondary" badgeContent={100} max={99}>*/}
                            {/*        <Share style={{height: 36, width: 36}}/>*/}
                            {/*    </Badge>*/}
                            {/*</IconButton>*/}
                            <IconButton aria-label="点赞" style={{marginLeft:12}} onClick={()=>{this.setState({isLiked:!this.state.isLiked})}}>
                                <Favorite style={{height:36,width:36,color:this.state.isLiked?'#E05050':'#909090'}}/>
                            </IconButton>
                            <IconButton aria-label="评论" style={{marginLeft:12}} onClick={()=>{this.setState({showComments:!this.state.showComments})}}>
                                <Comment style={{height:36,width:36}}/>
                            </IconButton>
                            <IconButton aria-label="分享" style={{marginLeft:12}}>
                                <Share style={{height:36,width:36}}/>
                            </IconButton>
                        </div>
                    </div>
                    {this.state.showComments?(
                        <EntryAnim
                            duration={'1s'}
                            type={'fadeInRight'}
                            partialVisibility={true}
                        >
                            <div style={{height:12}}/>
                            {this.props.user?(
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginTop:12,padding:12}}>
                                    <Avatar
                                        style={{
                                            width:64,
                                            height:64,
                                            marginRight:24,
                                        }}
                                        alt="Remy Sharp"
                                        src={this.avatar}
                                    />
                                    <TextField style={{flexGrow:1}} id="comment" multiline={true} rows={3} label="您的评论..." variant="outlined" />
                                    <FollowButton
                                        onClick={async ()=>{
                                            const body = document.getElementById('comment').value;
                                            const res = await createComment({body:body,postId:this.props.data.id})
                                            if(res.success){
                                                const _comments = [...this.state.comments];
                                                _comments.push({
                                                    body:body,
                                                    createdAt:moment(),
                                                    id:res.data.id,
                                                    replies:[],
                                                    replyNum:0,
                                                    user:this.props.user,
                                                    userId:this.props.user.id,
                                                });
                                                this.setState({comments:_comments},()=>{document.getElementById('comment').value=null});
                                                alert('评论成功')
                                            }
                                        }}
                                        style={{marginLeft:24,width:96,height:72,backgroundColor:'#E05050',display:'flex',alignItems:'center',justifyContent:'center',zIndex:10}}>
                                        <a style={{color:'whitesmoke',fontWeight:'600',fontSize:18}}>提交评论</a>
                                    </FollowButton>
                                </div>
                            ):(
                                <Card style={{width: '100%',height:80,display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#A0A0A0'}} className="neighblog-card">
                                    <text style={{fontSize:20,color:"#606060"}}>登陆后才能发表评论</text>
                                </Card>
                            )}
                            <div style={{height:12}}/>
                            <ScrollBars
                                style={{height:240}}
                            >
                                {this.renderComments(this.state.comments)}
                            </ScrollBars>
                        </EntryAnim>
                    ):(null)}
                </Paper>
            </EntryAnim>
        )
    }
}

import React from "react";
import {Link, Route, Router,Redirect} from "react-router-dom";
import {ThemeProvider,createMuiTheme} from "@material-ui/core/styles";
import {createBrowserHistory} from "history";
import {Layout, Menu, Popover} from "antd";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import WelcomePage from "./views/Welcome/WelcomePage";
import VideoPage from "./views/VideoPage/VideoPage";
import FoundationPage from "./views/FoundationPage/FoundationPage";
import AboutUsPage from "./views/AboutUsPage/AboutUsPage";
import ContactUsPage from "./views/ContactUsPage/ContactUsPage";
import NewPostPage from "./views/VideoPage/NewPostPage";
import EditPostPage from "./views/VideoPage/EditPostPage";
import LoginPage from "./views/UserPage/LoginPage";
import {auth,logout} from "./views/UserPage/Service";
import {Avatar,MenuItem} from "@material-ui/core";
// import 'antd/dist/antd.css';
// import './index.css';
// import './views/general.css';
// import "assets/scss/material-kit-react.scss?v=1.9.0";






const { Content, Footer , Header } = Layout;



const headerTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#602020',
        },
        secondary: {
            main: '#E05050',
        },
    },
});



const logo = require('./assets/img/logo.jpg');

const hist = createBrowserHistory();

const navLinks = [
    {path:'/',label:'首页'},
    {path:'/video',label:'中华电视频道'},
    {path:'/foundation',label:'中华文化基金会'},
    {path:'/aboutUs',label:'关于我们'},
    {path:'/contactUs',label:'联系我们'},
    {path:'/login',label:'登录&注册'},
]


function PrivateRoute({ component: Component, ...rest }) {
    const [isAuthenticated, setAuth] = React.useState(false);
    const [isReady, setReady] = React.useState(false);
    React.useEffect(() => {
        auth()
            .then((res) => {
                if (res.success === true) {
                    setAuth(true);
                    setReady(true);
                } else {
                    setReady(true);
                }
            })
            .catch((e) => {
                setReady(true);
            });
    }, []);

    return isReady ? (
        <Route
            {...rest}
            render={(props) =>{
                if(isAuthenticated){
                    return <Component {...props} />
                }else {
                    alert('请先登录')
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            }}
        />
    ) : null;
}

class LoginWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = { redirectToReferrer: false };
    }


    login = () => {
        this.setState({ redirectToReferrer: true });
    };

    render() {
        const { from } = this.props.location?.state || { from: { pathname: "/" } };
        const { redirectToReferrer } = this.state;

        if (redirectToReferrer) {
            return <Redirect to={from} />;
        }
        return <LoginPage storeUserInfo={this.props.storeUserInfo} loginCallback={this.login}/>;
    }
}

export default class App extends React.Component{
    constructor(props) {
        super(props);
        let initialIndex = 0;
        for(let i=0;i<navLinks.length;i+=1){
            if(hist.location.pathname===navLinks[i].path){
                initialIndex = i;
                break;
            }
        }
        this.state={
            currentIndex:initialIndex,
            userInfo:null,
        }
    }
    componentDidMount() {
        auth()
            .then((res) => {
                if (res.success === true) {
                    this.setState({userInfo:res.data})
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {
        return(
            <Router history={hist}>
                <ThemeProvider theme={headerTheme}>
                    <Layout className="layout">
                        <AppBar position={'fixed'} color={'primary'}>
                            <Toolbar>
                                <div style={{width:64}}/>
                                <div style={{minWidth:240,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <IconButton edge="start" color="inherit" aria-label="menu">
                                        <img src={logo} width={64} height={'auto'}/>
                                    </IconButton>
                                    <Typography variant="h5" style={{color:'whitesmoke'}}>
                                        澳大利亚中华电视
                                    </Typography>
                                </div>
                                <div style={{flexGrow:1}}/>
                                <div style={{minWidth:960,display:"flex"}}>
                                    {navLinks.map((item,i)=>{
                                        const highlightStyle = {};
                                        if(this.state.currentIndex === i){
                                            highlightStyle.fontWeight = '700';
                                            highlightStyle.color = '#FA0000';
                                            highlightStyle.fontSize = 20;
                                            highlightStyle.backgroundColor = '#00000020';
                                        }
                                        const InnerBlock = ()=>(
                                            <Link to={item.path}>
                                                <div
                                                    key={i}
                                                    className={'navBtn'}
                                                    onClick={()=>{this.setState({currentIndex:i})}}
                                                    style={{...highlightStyle}}>{item.label}</div>
                                            </Link>
                                        )
                                        if(i===5){
                                            if(!this.state.userInfo){
                                                return <InnerBlock/>
                                            }else {
                                                return (
                                                    <Popover
                                                        style={{padding:0}}
                                                        content={<div style={{width:200}}>

                                                            <MenuItem>个人信息</MenuItem>
                                                            <MenuItem
                                                            onClick={async ()=>{
                                                                const res = await logout();
                                                                if(res.success){
                                                                    alert('已登出');
                                                                    window.location.href='/'
                                                                }
                                                            }}
                                                            >退出登录</MenuItem>
                                                        </div>}>
                                                        <div style={{height:1,width:1,position:'absolute',zIndex:12,right:0}}/>
                                                            <div
                                                                key={i}
                                                                className={'navBtn'}
                                                                //onClick={()=>{this.setState({currentIndex:i})}}
                                                                style={{
                                                                    ...highlightStyle,
                                                                    display:'flex',
                                                                    maxWidth:'200',
                                                                }}
                                                            >
                                                                <Avatar
                                                                    style={{
                                                                        width:32,
                                                                        height:32,
                                                                        marginRight:12,
                                                                    }}
                                                                    alt="Remy Sharp"
                                                                    src={require('./assets/img/defaultAvatar.jpg')}
                                                                />
                                                                <a style={{color:'whitesmoke'}}>{this.state.userInfo.username}</a>
                                                            </div>
                                                    </Popover>
                                                )
                                            }
                                        }
                                        return (<InnerBlock/>)
                                    })}
                                </div>
                                <div style={{width:24}}/>
                            </Toolbar>
                        </AppBar>
                        <Content>
                            <Route exact path="/" component={WelcomePage}/>
                            <Route path="/video" component={()=>(<VideoPage user={this.state.userInfo}/>)}/>
                            <Route path="/foundation" component={FoundationPage}/>
                            <Route path="/aboutUs" component={AboutUsPage}/>
                            <Route path="/contactUs" component={ContactUsPage}/>
                            <PrivateRoute path="/newPost" component={NewPostPage}/>
                            <PrivateRoute path="/editPost" component={EditPostPage}/>
                            <Route path="/login" component={()=>(<LoginWrapper storeUserInfo={(info)=>{this.setState({userInfo:info})}}/>)}/>
                        </Content>
                        <Footer style={{backgroundColor:'#602020',boxShadow:'0 -4px 4px #00000060'}}>
                            <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}>
                                <div style={{flexGrow:2}}/>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <img src={logo} width={72} height={'auto'}/>
                                    <text style={{marginLeft:12,fontSize:24,color:'whitesmoke',fontWeight:700}}>澳大利亚中华电视</text>
                                </div>
                                <div style={{flexGrow:1}}/>
                                <div style={{textAlign:'right',color:'whitesmoke',display:'flex',flexDirection:'column',lineHeight:0.8}}>
                                    <text style={{fontSize:18,fontWeight:'500'}}>中华电视以中华文化在澳大利亚的融合为主要传播主旨。</text><br/>
                                    <text style={{fontSize:18,fontWeight:'500'}}>中华电视传播中华，中华电视传承中华。</text>
                                </div>
                                <div style={{flexGrow:2}}/>
                            </div>
                            <div style={{textAlign:'center',color:'whitesmoke',marginTop:24}}>
                                <text style={{fontSize:16,letterSpacing:1}}>Copyright © 2021 澳大利亚中华电视 All Rights Reserved.</text>
                            </div>
                        </Footer>
                    </Layout>
                </ThemeProvider>
            </Router>
        )
    }
}

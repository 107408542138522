import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import Radium, {StyleRoot} from 'radium';


//
// props:{
//     type:STRING //'fadeInLeft';
//     duration:STRING //'1s';
//     partialVisibility:BOOLEAN;
//     placeholder:React.Node;
//     (注：至少为一个宽高2px的div，否则无法正确触发visibilitySensor；不填或设null则用hidden原元素占位，渲染滚动列表好用，但用作展示页可能导致同区块触发点不一致。)
//     children
// }


export default class EntryAnim extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            hasSeen:false,
        }
        const anim = require('react-animations')[props.type];
        this.styles = {
            localAnim: {
                animation: `x ${props.duration}`,
                animationName: Radium.keyframes(anim, 'localAnim')
            }
        }
    }

    render() {
        return (
            <StyleRoot>
                <VisibilitySensor
                    partialVisibility={this.props.partialVisibility}
                    active={!this.state.hasSeen}
                    onChange={(isVisible)=>{
                        if(isVisible){
                            this.setState({hasSeen:true})
                        }
                    }}
                >
                    {({isVisible}) =>{
                        return((isVisible === true)?(
                            <div style={this.styles.localAnim}>
                                {this.props.children}
                            </div>
                        ):(this.props.placeholder?(
                                <>
                                    {this.props.placeholder}
                                </>
                            ):(
                                <div style={{visibility:'hidden'}}>
                                    {this.props.children}
                                </div>
                            )
                        ))
                    }}
                </VisibilitySensor>
            </StyleRoot>
        )
    }
}


import React from "react";

import { Parallax ,Background} from 'react-parallax';
import {Paper} from "@material-ui/core";
import {Button} from "antd";
import EntryAnim from "../../utils/EntryAnim";
import UseViewport from "../../utils/UseViewport";
import '../general.css';
import useViewport from "../../utils/UseViewport";

const dashboardRoutes = [];

const bg1 = require('assets/img/newBG3.jpg');
const bg2 = require('assets/img/newBG4.jpg');
const sloganImage = require("assets/img/slogan.png");
const logoImage = require("assets/img/logo.jpg");
const block2Image= require("assets/img/su.jpg");
const block3Image= require("assets/img/su.jpg");



const styles = {
    //fontFamily: "sans-serif",
    //textAlign: "center",
    //backgroundColor:'#600000',
};
const blockStyle = {
    width:'100%',
    height:'100%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',

};

const ContactBtn=(props)=>(
    <Paper
        elevation={5}
        onClick={()=>{props.onClick()}}
        style={{marginTop:24,marginBottom:12,width:180,height:48,backgroundColor:'#E05050',borderRadius:0,display:'flex',alignItems:'center',justifyContent:'center'}}>
        <a style={{color:'whitesmoke',fontWeight:'600',fontSize:18}}>{props.text}</a>
    </Paper>
)


export default function LandingPage(props) {
    const {width:windowWidth,height:windowHeight} = useViewport();
    return (
        <div style={styles}>
            <Parallax bgImage={bg1} bgImageStyle={{width:windowWidth,height:'auto',minHeight:280,objectFit:'cover'}} strength={500}>
                <div style={{
                    height:200,
                    width:'100%',
                    display:'flex',
                    flexDirection:'column',
                    backgroundColor:'#FFFFFF20',
                    alignItems:'center',
                    justifyContent:'center',
                }}>
                    <div style={{
                        display:'flex',
                        width:'100%',
                        height:160,
                        alignItems:'center',
                        backgroundColor:'#00000060'
                    }}>
                        <div style={{flexGrow:1}}/>
                        <EntryAnim
                            type={'fadeInLeft'}
                            duration={'1.6s'}
                            partialVisibility={false}
                            placeholder={<div style={{height:2,width:2}}/>}
                        >
                            <div style={{display:'flex',flexDirection:'column',color:'whitesmoke'}}>
                                <text style={{fontSize:40,fontWeight:'700',lineHeight:1.6,letterSpacing:1,textAlign:"start"}}>中华文化基金会</text>
                                <text style={{fontSize:20,fontWeight:'500',textAlign:"start"}}>澳大利亚中华电视·中华文化基金会</text>
                            </div>
                        </EntryAnim>
                        <div style={{flexGrow:3}}/>
                    </div>
                </div>
            </Parallax>
            <Parallax bgImage={bg2} strength={500} blur={{ min: 3, max: -3 }}>
                <div style={{ height: 'auto' ,backgroundColor:'#F0F0F0A0',paddingTop:24,paddingBottom:24}}>
                    <div style={blockStyle}>
                        <EntryAnim
                            type={'fadeInRight'}
                            duration={'2s'}
                            partialVisibility={true}
                            //placeholder={<div style={{height:2,width:2}}/>}
                        >
                            <img src={block2Image} width={0.9*windowWidth} height={'auto'} style={{objectFit:'cover',boxShadow:'4px 4px 4px #00000090'}} />
                        </EntryAnim>
                        <div style={{height:48}}/>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <EntryAnim
                                type={'fadeInUp'}
                                duration={'2s'}
                                partialVisibility={true}
                                //placeholder={<div style={{height:2,width:2}}/>}
                            >
                                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                    <Paper elevation={5} style={{backgroundColor:"whitesmoke",width:0.9*windowWidth,padding:18,borderRadius:0,paddingTop:18,paddingBottom:18}}>
                                        <div style={{marginTop:12,padding:12}}>
                                            <EntryAnim
                                            type={'fadeIn'}
                                            duration={'1.4s'}
                                            partialVisbility={false}
                                            placeholder={<div style={{height:2,width:2}}/>}
                                            >
                                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:18}}>
                                                <div style={{width:12,height:48,backgroundColor:'#E05050',marginRight:12}}/>
                                                <text style={{fontSize:32,fontWeight:'700'}}>中华文化基金会</text>
                                            </div>
                                            </EntryAnim>
                                            <EntryAnim
                                                type={'fadeIn'}
                                                duration={'1.6s'}
                                                partialVisbility={true}
                                                placeholder={<div style={{height:2,width:2}}/>}
                                            >
                                            <p className={'blockText'}>澳大利亚中华文化基金会是一个以中华人为组成人员的非营利性文化传播社会组织,旨在传播中华文明,服务中华子民。会员信守行善乐人,助危扶弱,帮困济心,为民礼德的会训,把人性沟通,爱心传递,友善和谐,真诚互助做行为准则,传递中华人民的友好、友善、友情。</p>
                                            <p className={'blockText'}>澳大利亚中华文化基金会主席宿陲婴女士是移居澳大利亚15年的华侨。她是一名在中国辽宁沈阳曾经获得过很多荣誉的女士。在中国铁路史上第一个被授予“红旗列车”的12次列车,是50年前在洪水中救出1500多名遇难旅客和灾民的英雄包车组。宿女士在20岁时就是这个红旗列车的列车长,服务人民的真实生活训练心灵的真诚,教人真心实意为人服务。</p>
                                            <p className={'blockText'}>来到澳大利亚的宿女士心系中华,成立中华文化基金会,用她真心真情为中华文化的传播工作。她邀请维多利亚州华人小区服务中心的梁国霖太平绅士做会长,杨衍柏太平绅士做秘书长,罗张玉燕做副主席,陈荣华会计师做财务长,共同为中华文化传播付出真心真情。</p>
                                            <p className={'blockText'}>中华文化基金会愿同各友好社团、各友爱团体相互联谊,相互帮助,相互促进,和谐共生,合作共心,和合同行。</p>
                                            </EntryAnim>
                                            <EntryAnim
                                                type={'fadeInLeft'}
                                                duration={'2s'}
                                                partialVisibility={true}
                                                //placeholder={<div style={{height:200,width:200,backgroundColor:'lime',marginTop:600}}/>}
                                            >
                                                <ContactBtn onClick={()=>{window.location.href='/contactUs'}} text={'联系我们'}/>
                                            </EntryAnim>
                                        </div>
                                    </Paper>
                                </div>
                            </EntryAnim>
                        </div>
                    </div>
                </div>
            </Parallax>
        </div>
    );
}

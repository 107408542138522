export const memberData=[
    {avatarUrl:'assets/img/team/su.jpg',
     text:'主席： 宿陲婴\n' +
         '中华卫星电视集团(澳大利亚)有限公司董事长。\n' +

         '1996年移居澳大利亚，从事过多种职业。\n' +

         '2010年创办澳大利亚中华文化基金会，成立中华卫星电视集团(澳大利亚)有限公司。\n' +

         '她的生命格言：贵人 不争 罪己 施爱'
    },
    {avatarUrl:'assets/img/team/liangguolin.jpg',
        text:'副主席：梁国霖\n' +
            '梁国霖太平绅士是澳大利亚中华文化基金会会长。\n' +

            '十几年从事社区义务服务，把奉献爱心、服务华人、关怀乡亲、帮助社团作为自己的生活，给很多华人社团义务工作。\n' +

            '他把自己叫做：一个大义工。\n' +

            '他的最大快乐就是帮助华人。'
    },
    {avatarUrl:'assets/img/team/eddie.jpg',
        text:'摄像制作：EDDIE\n' +
            '一个把中华电视当成自己家的澳洲人。\n' +

            '他是世界小电影制作大赛的金奖获得者，资深的电影制作人。\n' +

            '他把自己的喜好和中华文化相融合，制作中华电视记录片。\n' +

            '他说：他很荣幸能够成为中华电视的一员。'
    },
    {avatarUrl:'assets/img/team/wangyue.jpg',
        text:'摄像制作：王越\n' +
            '移民澳洲多年，把电视制作当成了自己生命的追求，为华人社区奉献很多美丽的作品。\n' +

            '从专题片到电视剧，王越给自己的生命录制了美丽的人生。\n' +

            '他帮助中华电视开播、帮助中华电视拍摄、帮助中华电视制作节目。\n' +

            '他把帮助中华电视的爱心付出当成快乐的事情。'
    },
    {avatarUrl:'assets/img/team/liuguoxing.jpg',
        text:'摄像摄影：刘安兴\n' +
            '喜爱自然美景，关照心灵感悟，把自己的生活融进自然之中，用心感觉生命的自己，用摄像机、照相机记录生命自在自为自生。\n' +
            '\n' +
            '在中华电视的节目里，留下了他用心拍摄的自然美丽。'
    },
    {avatarUrl:'assets/img/team/paul.jpg',
        text:'英文编导：Paul\n' +
            '喜欢思考美好浪漫的故事，喜好编写浪漫情书的自由撰稿人。\n' +
            '\n' +
            '他的浪漫故事拍成了小电影，在爱情小电影的世界大赛里一举夺魁，获得金奖。\n' +
            '\n' +
            '他带着美好的情怀来到中华电视，他又开始在中华电视编导美丽浪漫的故事。' +
            'PAUL编导的《爱无边界》电视纪实报道，是中华电视奉献给中西和婚家庭的美好生活记录。'
    },
    {avatarUrl:'assets/img/team/su.jpg',
        text:'\n' +
            '中文编导： 宿陲婴\n' +
            '把传播中华文化作为生命的自觉，用自己的真情实感撰写文字，把生命的真实感悟留存在中华电视的字里行间，编导美丽的生活。清淳、自然、灵秀的表达是宿陲婴的艺术追求。\n' +
            '\n' +
            '她把自己的追求记录在中华电视的神采里。'
    },
    {avatarUrl:'assets/img/team/lucy.jpg',
        text:'\n' +
            '文字翻译： Lucy\n' +
            '家住广州，留学澳洲，生活在墨尔本。' +
            '学习传媒制作，研读语言翻译，热爱中华文化的传播。\n' +
            '\n' +
            '她把自己求学之余的很多时间奉献给中华电视。她喜欢用自己的所学帮助中华电视。\n' +
            '\n' +
            '在中华电视的很多节目里有她的译文。' +
            '她自己把译文一字一句放进中华电视的节目里，让观众品读。'
    },
    {avatarUrl:'assets/img/team/skye.jpg',
        text:'节目主持： Skye\n' +
            '在澳洲长大的skye是中华电视开播节目的主持人。\n' +
            '\n' +
            '她很喜爱中华文化，很努力把中华电视节目里的中文用汉语播出。\n' +
            '\n' +
            '她的播音很有澳洲的中文汉语味道，很有中西文化融合的传播趣味。'
    },
    {avatarUrl:'assets/img/team/liyan.jpg',
        text:'节目主持： 莉艳\n' +
            '来到澳洲从ABC开始学习英文，为中华电视主持节目的时候硬是背诵主持文稿。\n' +
            '\n' +
            '她把中英文用汉语、英语播报，播出的不仅是声音，还有她那颗热爱中华文化的心中情。'
    },
    {avatarUrl:'assets/img/team/qimiao.jpg',
        text:'\n' +
            '节目主持： 齐妙\n' +
            '把主持、配音、采访、编辑主动承担在自己的身上，用自己对传媒事业的喜爱心情去工作，去努力，去奉献，在中华电视的节目里把爱心表达，这是齐妙的快乐选择。'
    },
    {avatarUrl:'assets/img/team/yangguosheng.jpg',
        text:'\n' +
            '专题访谈：陈杨国生\n' +
            '澳大利亚高等教育领域的资深学者，墨尔本皇家理工大学语言系的系主任。\n' +
            '\n' +
            '她多年来把帮助各类传媒，传播中华文化和多元文化作为己任，更把中华电视的发展记在心上。\n' +
            '\n' +
            '在中华电视开设专栏，访谈那些心中藏有中华情结的人，请人在中华电视里诉说家事、心事、人事，把人和家、人和人、人和心的美好故事发表在中华电视里。'
    },
    {avatarUrl:'assets/img/team/sushuangyi.jpg',
        text:'\n' +
            '电视配音：宿双一\n' +
            '自幼喜爱文化娱乐的各种表演活动，来到澳洲之后专心培养儿子“yo-yo”。\n' +
            '\n' +
            '中华电视开播需要配音人员，他主动请缨上阵，承担中华电视的配音工作。\n' +
            '\n' +
            '中华电视的男声就是宿双一的表达。'
    },
    {avatarUrl:'assets/img/team/luyan.jpg',
        text:'电视记者：卢艳\n' +
            '北京来的女孩，在澳洲读语言、进中学、上大学、找工作、当义工、办公司，一点点在澳洲生活、成长、安家，把自己安排得安稳、安乐。\n' +
            '\n' +
            '给中华电视做事是她从心出发的自愿，她喜欢中华文化传播的事业，喜欢用自己的爱心帮助中华电视。'
    },
    {avatarUrl:'assets/img/team/sujia.jpg',
        text:'\n' +
            '电视记者：宿佳\n' +
            '职业是会计，乐意帮助中华电视做事。\n' +
            '\n' +
            '她把自己的业余时间交给中华电视调遣，只要中华电视有需要，她就会马上报到，上阵出场，采访、主持、配音、翻译，她都来帮忙做。\n' +
            '\n' +
            '她把帮助中华电视工作当成自己的一份责任。'
    },
]

import React from "react";
import ReactDOM from "react-dom";
import PCApp from "./PCApp";
import MOApp from "./MOApp";

import 'antd/dist/antd.css';
import './index.css';

import "assets/scss/material-kit-react.scss?v=1.9.0";

let ua = {}
const ie_upto10 = /MSIE \d/.test(navigator.userAgent)
const ie_11up = /Trident\/(?:[7-9]|\d{2,})\..*rv:(\d+)/.exec(navigator.userAgent)
const ie_edge = /Edge\/(\d+)/.exec(navigator.userAgent)
ua.mac = /Mac/.test(navigator.platform)
let ie = ua.ie = !!(ie_upto10 || ie_11up || ie_edge)
ua.ie_version = ie_upto10 ? document.documentMode || 6 : ie_11up ? +ie_11up[1] : ie_edge ? +ie_edge[1] : null
ua.gecko = !ie && /gecko\/(\d+)/i.test(navigator.userAgent)
ua.gecko_version = ua.gecko && +(/Firefox\/(\d+)/.exec(navigator.userAgent) || [0, 0])[1]
let chrome = !ie && /Chrome\/(\d+)/.exec(navigator.userAgent)
ua.chrome = !!chrome
ua.chrome_version = chrome && +chrome[1]
// Is true for both iOS and iPadOS for convenience
ua.safari = !ie && /Apple Computer/.test(navigator.vendor)
ua.ios = ua.safari && (/Mobile\/\w+/.test(navigator.userAgent) || navigator.maxTouchPoints > 2)
ua.android = /Android \d/.test(navigator.userAgent)
ua.webkit = "webkitFontSmoothing" in document.documentElement.style
ua.webkit_version = ua.webkit && +(/\bAppleWebKit\/(\d+)/.exec(navigator.userAgent) || [0, 0])[1]





class AppContainer extends React.Component {
    render() {
         if(ua.android||ua.ios){
             return <MOApp/>
         }else {
             return <PCApp/>
         }
    }
}


ReactDOM.render(
    <AppContainer/>,
  document.getElementById("root")
);

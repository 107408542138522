import React from "react";
import {Button} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";



const CateButton = withStyles((theme) => ({
    root: {
        color: 'whitesmoke',
        borderRadius:0,
        //backgroundColor: '#00000090',
        '&:hover': {
            backgroundColor: 'whitesmoke',
            color:'#e05050'
        },
        display:'flex',
        width:'100%',
        height:'100%',
        justifyContent:'flex-start'
    },
}))(Button);

const CateButton_Highlight = withStyles((theme) => ({
    root: {
        color: '#e05050',
        borderRadius:0,
        //backgroundColor: '#00000090',
        '&:hover': {
            backgroundColor: '#FFFFFF90',
            color:'#e05050'
        },
        display:'flex',
        width:'100%',
        height:'100%',
        justifyContent:'flex-start',
    },
}))(Button);




export default class CategoryButton extends React.Component{

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div
                style={{
                    width:'100%',
                    height:72,
                }}
            >
                {this.props.highlighted?
                    (
                        <CateButton_Highlight
                            onClick={()=>{this.props.onClick()}}
                        >
                            <div style={{margin:12,display:'flex'}}>
                                {this.props.icon}
                            </div>
                            <text style={{fontSize:20,fontWeight:'bold'}}>{this.props.title}</text>
                        </CateButton_Highlight>
                    ):(
                        <CateButton
                            onClick={()=>{this.props.onClick()}}
                        >
                            <div style={{margin:12,display:'flex'}}>
                                {this.props.icon}
                            </div>
                            <text style={{fontSize:20}}>{this.props.title}</text>
                        </CateButton>
                    )
                }
            </div>
        )
    }
}


import React from "react";

import { Parallax ,Background} from 'react-parallax';
import {Paper} from "@material-ui/core";
import {Row,Col} from "antd";
import EntryAnim from "../../utils/EntryAnim";

import '../general.css';
import useViewport from "../../utils/UseViewport";
import {memberData} from "./MemberData";
import MemberCard from "./Components/MemberCard";

const dashboardRoutes = [];

const bg1 = require('assets/img/newBG3.jpg');
const bg2 = require('assets/img/newBG5.jpg');
const bg3 = require('assets/img/newBG9.jpg');
const sloganImage = require("assets/img/slogan.png");
const logoImage = require("assets/img/logo.jpg");
const block2Image= require("assets/img/su.jpg");
const block3Image= require("assets/img/su.jpg");


const styles = {
    //fontFamily: "sans-serif",
    //textAlign: "center",
    //backgroundColor:'#600000',
};
const blockStyle = {
    width:'100%',
    height:'100%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',

};

const ContactBtn=(props)=>(
    <Paper
        elevation={5}
        onClick={()=>{props.onClick()}}
        style={{marginTop:24,marginBottom:12,width:180,height:48,backgroundColor:'#E05050',borderRadius:0,display:'flex',alignItems:'center',justifyContent:'center'}}>
        <a style={{color:'whitesmoke',fontWeight:'600',fontSize:18}}>{props.text}</a>
    </Paper>
)

const colStyle = {
    display:'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    minWidth:360
}

export default function LandingPage(props) {
    const {width:windowWidth,height:windowHeight} = useViewport();
    return (
        <div style={styles}>
            <Parallax bgImage={bg1} bgImageStyle={{width:windowWidth,height:'auto',minHeight:280,objectFit:'cover'}} strength={500}>
                <div style={{
                    height:200,
                    width:'100%',
                    display:'flex',
                    flexDirection:'column',
                    backgroundColor:'#FFFFFF20',
                    alignItems:'center',
                    justifyContent:'center',
                }}>
                    <div style={{
                        display:'flex',
                        width:'100%',
                        height:160,
                        alignItems:'center',
                        backgroundColor:'#00000060'
                    }}>
                        <div style={{flexGrow:1}}/>
                        <EntryAnim
                            type={'fadeInLeft'}
                            duration={'1.6s'}
                            partialVisibility={false}
                            placeholder={<div style={{height:2,width:2}}/>}
                        >
                            <div style={{display:'flex',flexDirection:'column',color:'whitesmoke'}}>
                                <text style={{fontSize:40,fontWeight:'700',lineHeight:1.6,letterSpacing:1,textAlign:"start"}}>关于我们</text>
                                <text style={{fontSize:20,fontWeight:'500',textAlign:"start"}}>澳大利亚中华电视·关于我们</text>
                            </div>
                        </EntryAnim>
                        <div style={{flexGrow:3}}/>
                    </div>
                </div>
            </Parallax>
            <Parallax bgImage={bg2} strength={500} blur={{ min: 3, max: -3 }}>
                <div style={{
                    height:'auto',
                    width:'100%',
                    display:'flex',
                    paddingTop:24,
                    paddingBottom:24,
                    backgroundColor:'#FFFFFF20',
                    alignItems:'center',
                    justifyContent:'center',
                }}>
                    <Paper elevation={5} style={{backgroundColor:"whitesmoke",opacity:0.8,width:0.9*windowWidth,padding:18,borderRadius:0,paddingTop:18,paddingBottom:18}}>
                        <EntryAnim
                            type={'fadeInDown'}
                            duration={'1.4s'}
                            partialVisbility={false}
                            placeholder={<div style={{height:2,width:2}}/>}
                        >
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:32}}>
                                <div style={{width:12,height:72,backgroundColor:'#E05050',marginRight:12}}/>
                                <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                    <text style={{fontSize:32,fontWeight:'700',lineHeight:1.6}}>澳大利亚中华电视</text>
                                    <text style={{fontSize:20}}>中华电视栏目介绍</text>
                                </div>
                            </div>
                        </EntryAnim>
                        <EntryAnim
                            type={'fadeInUp'}
                            duration={'1.6s'}
                            partialVisbility={true}
                            placeholder={<div style={{height:2,width:2}}/>}
                        >
                            <p className={'blockText'}>中华电视是一个以中华文化在澳大利亚的融合为主要传播主旨的电视机构。</p>
                            <p className={'blockText'}>中华电视把拍摄华人在澳大利亚的生活、对澳洲社会的贡献、同其他民族的融合作为主要内容，把爱心奉献社会、热心服务他人、诚心支持国家的美好行为作为宣传重点，在多元文化的互相交融、互相交流、互相交际的过程中，发现人类和美的友爱故事，在澳大利亚这个美丽的国家，奉献中华文明，表现华人爱心。</p>
                            <p className={'blockText'}>中华电视是一个公益性的传播机构，致力于文化融合的多元性，用摄像机记录多元文化的交流，拍摄多民族共同生活的场景，展现澳大利亚美丽美好的生活。</p>
                            <p className={'blockText'}>中华电视在墨尔本C31电视台的栏目播出，每周自己拍摄制作节目，表达中华电视的服务主旨。</p>
                        </EntryAnim>
                    </Paper>
                </div>
            </Parallax>
            <Parallax bgImage={bg3}  bgImageStyle={{width:windowWidth,objectFit:'fill'}} strength={500} blur={{ min: 2, max: 2 }}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',height:'auto'}}>
            <Paper elevation={5} style={{display:'flex',flexDirection:'row',justifyContent:'center',minWidth:1080,width:'100%',paddingTop:48,backgroundColor:'whitesmoke',opacity:0.8,borderRadius:0}}>
                    <Row gutter={4}>
                        <Col span={24} style={{marginTop:48,marginBottom:24,alignItems: 'center',justifyContent:'center',display:'flex',flexDirection: 'column'}}>
                            <text style={{fontWeight:'500',fontSize:36,marginBottom:24,letterSpacing:2}}>中华电视制作团队</text>
                            <div style={{height:6,width:128,backgroundColor:'#E05050'}}/>
                        </Col>
                        {memberData.map((item,i)=>{
                            const duration =1.2;
                            return(
                                <Col span={24} style={colStyle}>
                                    <EntryAnim
                                        type={'fadeInRight'}
                                        duration={`${duration}s`}
                                        partialVisibility={true}
                                        //placeholder={<div style={{width:2,height:600}}/>}
                                    >
                                        <MemberCard
                                            avatarUrl={item.avatarUrl}
                                            text={item.text}
                                        />
                                    </EntryAnim>
                                </Col>
                            )
                        })}
                    </Row>
            </Paper>
            </div>
            </Parallax>
        </div>
    );
}

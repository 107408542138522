import React from "react";
import {Avatar} from "@material-ui/core";
import './localStyles.css'

export default class MemberCard extends React.Component{
    constructor(props) {
        super(props);
        const parsedText = props.text.split('\n').filter((item)=>(item));
        this.title = parsedText[0];
        const avatarName = props.avatarUrl.split('/').pop();
        this.avatar = require(`assets/img/team/${avatarName}`);
        const paragraphText = [...parsedText];
        paragraphText.splice(0,1);
        this.paragraphs = paragraphText.map((item)=>(<text className={'memberCard'}>{item}</text>))
    }
    render() {
        return (
            <div style={{display:'flex',width:360,flexDirection:'column',padding:24,alignItems:'center'}}>
                <Avatar alt={this.title} src={this.avatar} style={{height:256,width:256}} />
                <text style={{fontSize:24,fontWeight:'700',lineHeight:2,margin:12}}>{this.title}</text>
                {this.paragraphs}
            </div>
        )
    }
}

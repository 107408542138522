import React from "react";

export const resolveVideoUrl = (input)=>{
    const youkuReg = /v\.youku\.com\/v_show\/id_(.+)\.html/gi
    const biliReg = /bilibili\.com\/video\/([^?.]+)/gi
    if (youkuReg.test(input)) {
        return ({videoUrl:RegExp.$1,videoSource:'youku',height:720,width:360});
    }
    if (biliReg.test(input)) {
        return ({videoUrl:RegExp.$1,videoSource:'bilibili',height:720,width:360});
    }
    return null;
}
export const generateIframe = (data) => {
    switch (data.videoSource) {
        case 'youku':
            return (<iframe
                height={data.height}
                width={data.width}
                src={`https://player.youku.com/embed/${data.videoUrl}`}
                frameborder={0}
                allowFullScreen={true}
                webkitAllowFullScreen={true}
                mozAllowFullScreen={true}
            />)
        case 'bilibili':
            return (<iframe
                height={data.height}
                width={data.width}
                src={`//player.bilibili.com/player.html?bvid=${data.videoUrl}&page=1`}
                frameborder={0}
                allowFullScreen={true}
                sandbox={"allow-top-navigation allow-same-origin allow-forms allow-scripts"}
                webkitAllowFullScreen={true}
                mozAllowFullScreen={true}

            />)
        default:
            alert('不支持的视频链接');
            return null;
    }
}

import React from "react";
import {Link, Route, Router} from "react-router-dom";
import {ThemeProvider,createMuiTheme} from "@material-ui/core/styles";
import {createBrowserHistory} from "history";
import {Layout, Menu, Popover} from "antd";
import { Drawer, List, NavBar, Icon } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.min.css';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import WelcomePage from "./views/Welcome/WelcomePage_MO";
import VideoPage from "./views/VideoPage/VideoPage_MO";
import FoundationPage from "./views/FoundationPage/FoundationPage_MO";
import AboutUsPage from "./views/AboutUsPage/AboutUsPage_MO";
import ContactUsPage from "./views/ContactUsPage/ContactUsPage_MO";
import NewPostPage from "./views/VideoPage/NewPostPage";


//import 'antd/dist/antd.css';
import './index.css';
import './MOcss.css'
import './views/general.css';
import "assets/scss/material-kit-react.scss?v=1.9.0";
const { Content, Footer , Header } = Layout;
const logo = require('./assets/img/logo.jpg');


const routers = [
    {path:'/',label:'首页'},
    {path:'/video',label:'中华电视频道'},
    {path:'/foundation',label:'中华文化基金会'},
    {path:'/aboutUs',label:'关于我们'},
    {path:'/contactUs',label:'联系我们'},
]
const hist = createBrowserHistory()


export default class App extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            title:'澳大利亚中华电视',
        }
        this.linkList=(<List>
            {routers.map(item=>
                <Link to={item.path} key={item.path}  onClick={()=>this.onSelect(item.label)}>
                    <List.Item >
                        <div style={{
                            fontSize:20,
                            color:'white',
                        }}>{item.label}
                        </div>
                    </List.Item>
                </Link>
            )}
        </List>)
    }
    componentDidMount() {
      //  alert('mobile page')
    }
    onOpenChange=()=>{
        this.setState({open: !this.state.open})
    }
    onSelect = (label) => {
        this.setState({title:`${label}`});
        this.onOpenChange()
    }
    render() {
        return(
            <Router history={hist}>
                <NavBar icon={<Icon type="ellipsis" />} style={{height:64,backgroundColor:'#602020'}} onLeftClick={this.onOpenChange}><text style={{fontSize:24}}>{this.state.title}</text></NavBar>
                <Drawer
                    className="my-drawer"
                    style={{
                        minHeight: document.documentElement.clientHeight,
                        position: 'relative',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch'}}
                    enableDragHandle={true}
                    contentStyle={{ color: '#602020', textAlign: 'center'}}
                    // dragHandleStyle={{width:0}}
                    sidebar={this.linkList}
                    open={this.state.open}
                    onOpenChange={this.onOpenChange}
                >
                    <Route exact path="/" component={WelcomePage}/>
                    <Route path="/video" component={VideoPage}/>
                    <Route path="/foundation" component={FoundationPage}/>
                    <Route path="/aboutUs" component={AboutUsPage}/>
                    <Route path="/contactUs" component={ContactUsPage}/>
                    <Route path="/newPost" component={NewPostPage}/>

                </Drawer>
                {/*<Footer style={{backgroundColor:'#602020',boxShadow:'0 -4px 4px #00000060',padding:8,paddingTop:12,paddingBottom:16}}>*/}
                {/*    <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}>*/}
                {/*        <div style={{display:'flex',alignItems:'center',minWidth:192}}>*/}
                {/*            <img src={logo} width={48} height={'auto'}/>*/}
                {/*            <text style={{marginLeft:12,fontSize:18,color:'whitesmoke',fontWeight:700}}>澳大利亚中华电视</text>*/}
                {/*        </div>*/}
                {/*        <div style={{textAlign:'left',color:'whitesmoke',display:'flex',flexDirection:'column',lineHeight:1}}>*/}
                {/*            <text style={{fontSize:14,fontWeight:'500'}}>中华电视以中华文化与澳洲的融合为传播主旨。</text><br/>*/}
                {/*            <text style={{fontSize:14,fontWeight:'500'}}>中华电视传播中华，中华电视传承中华。</text>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div style={{textAlign:'center',color:'whitesmoke',marginTop:8,display:'flex',flexDirection:'column'}}>*/}
                {/*        <text style={{fontSize:12}}>Copyright © 2021 澳大利亚中华电视 All Rights Reserved.</text>*/}
                {/*        <a onClick={()=>{window.scroll(0,0)}} style={{color:'whitesmoke',textDecoration:'underline'}}>回到顶部</a>*/}
                {/*    </div>*/}
                {/*</Footer>*/}
            </Router>
        )
    }
}


export function createPost(body) {
    return new Promise(function (resolve, reject) {
        fetch("/api/post", {
            mode: "cors",
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}

export function editPost(body,id) {
    return new Promise(function (resolve, reject) {
        fetch(`/api/post/${id}`, {
            mode: "cors",
            method: "PUT",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}

export function queryPosts(body) {
    let params = "";
    if(body?.category){
        params+=`category=${body.category}`
    }
    if(body?.timeRef){
        params+=`category=${body.timeRef}`
    }
    return new Promise(function (resolve, reject) {
        fetch(`/api/post?${params}`, {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            params: JSON.stringify(body),
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}
export function deletePost(id) {
    return new Promise(function (resolve, reject) {
        fetch(`/api/post/${id}`, {
            mode: "cors",
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}
export function createComment(data) {
    return new Promise(function (resolve, reject) {
        fetch(`/api/post/${data.postId}/comment`, {
            mode: "cors",
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}
export function createReply(data) {
    return new Promise(function (resolve, reject) {
        fetch(`/api/post/${data.postId}/comment/${data.commentId}/reply`, {
            mode: "cors",
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}

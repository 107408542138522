import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {message,Tabs} from 'antd';
import InputAdornment from "@material-ui/core/InputAdornment";
import { Person, Lock } from "@material-ui/icons";
import {Paper,TextField, Button} from "@material-ui/core";
import sha256 from 'crypto-js/sha256';
import { login,auth,register } from "./Service";
import './Override.css'


const LoginBtn=(props)=>(
    <Button
        elevation={5}
        onClick={()=>{props.onClick()}}
        style={{marginTop:24,marginBottom:12,width:180,height:48,backgroundColor:'#602020',borderRadius:0,display:'flex',alignItems:'center',justifyContent:'center'}}>
        <text style={{color:'whitesmoke',fontWeight:'500',fontSize:18,fontDecoration:'null'}}>{props.text}</text>
    </Button>
)

export default function LoginPage(props) {
    React.useEffect(()=>{
        auth().then((res)=>{
            if(res.success){
                message.info('您已登录',2);
                window.location.href='/';
            }
        }).catch(e=>{console.log(e)})
    })
    const submitLogin = () => {
        let username = document.getElementById("username1").value;
        let password = document.getElementById("password1").value;

        login({
            username: username,
            password: sha256(password).toString(),
        })
            .then((res) => {
                console.log('res:::',res)
                if (res.success) {
                   props.storeUserInfo(res.data);
                   props.loginCallback();
                } else {
                    message.error('账号或密码错误。',2);
                    throw res;
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const submitReg = () => {
        let username = document.getElementById("username2").value;
        let password = document.getElementById("password2").value;


        register({
            username: username,
            password: sha256(password).toString(),
        })
            .then((res) => {
                console.log('res:::',res)
                if (res.success) {
                    window.location.href='/'
                } else {
                    message.error('发生错误，请稍后重试',2);
                    throw res;
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (

        <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:window.innerHeight-72}}>
            <Paper elevation={5} style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                flexDirection:'column',
                width:720,
                padding:24
            }}>
                <Tabs defaultActiveKey={'1'} style={{width:'100%',color:'#602020'}} type={'card'} size={'large'}>
                    <Tabs.TabPane tab={'登录'} key={'1'}>
                        <div style={{display:'flex',flexDirection:'column',width:'100%',height:'100%',alignItems:'center',justifyContent:'center'}}>
                            <text style={{fontSize:22,fontWeight:'bold',letterSpacing:1}}>登录账户</text>
                            <TextField style={{marginTop:48,width:'72%'}} id="username1" label="用户名" variant="outlined"
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start"><Person style={{color:'#602020'}}/></InputAdornment>,
                                       }} />
                            <TextField style={{marginTop:12,width:'72%'}} id="password1" label="密码" variant="outlined"
                                       type="password"
                                       onKeyDown={(event=>{if(event.key === 'Enter'){submitLogin()}})}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start"><Lock style={{color:'#602020'}}/></InputAdornment>,
                                       }} />
                            <LoginBtn text={'登录'} onClick={()=>{submitLogin()}} />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={'注册'} key={'2'}>
                        <div style={{display:'flex',flexDirection:'column',width:'100%',height:'100%',alignItems:'center',justifyContent:'center'}}>
                            <text style={{fontSize:22,fontWeight:'bold',letterSpacing:1}}>注册账户</text>
                            <TextField style={{marginTop:48,width:'72%'}} id="username2" label="用户名" variant="outlined"
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start"><Person style={{color:'#602020'}}/></InputAdornment>,
                                       }} />
                            <TextField style={{marginTop:12,width:'72%'}} id="password2" label="密码" variant="outlined"
                                       type="password"
                                       onKeyDown={(event=>{if(event.key === 'Enter'){submitReg()}})}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start"><Lock style={{color:'#602020'}}/></InputAdornment>,
                                       }} />
                            <LoginBtn text={'注册'} onClick={()=>{submitReg()}} />
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </Paper>
        </div>


    );
}

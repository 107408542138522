import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {Comment,Favorite,Share,Add,ArrowDropDown} from "@material-ui/icons";


import PostCard from "./components/PostCard_MO";
import CategoryButton from "./components/CategoryButton_MO";


import ScrollBars from 'react-custom-scrollbars';


import UseViewport from "../../utils/UseViewport";
import './components/PostCard.css';
import {Link, Route, Router} from "react-router-dom";
import {Drawer} from "antd-mobile";
import {Card, Divider} from "antd";
import withStyles from "@material-ui/core/styles/withStyles";
import {queryPosts} from "./Service";


const CateButton = withStyles((theme) => ({
    root: {
        color: 'whitesmoke',
        textAlign:'center',
        width:120,
        height:48,
        marginLeft:6,
        backgroundColor: '#E05050',
        borderColor:'gray',
        borderWidth:1,
        boxShadow:'2px 2px 2px #90909090',
        '&:hover': {
            backgroundColor: 'whitesmoke',
            color:'#e05050'
        },
        display:'flex',
        justifyContent:'flex-start'
    },
}))(Button);


export default function VideoPage(props) {
    const [videoSource,setVideoSource] = React.useState([]);

    const {height:windowHeight,width:windowWidth } = UseViewport();

    const [highlightedBtn,setHighlight] = React.useState(0);
    const [isDrawerOpen,toggleDrawer] = React.useState(false);

    const onOpenChange=()=>{
        toggleDrawer(!isDrawerOpen)
    }
    const cateList = ['全部分类','中华医药','中文教学','传统文化','艺术交流','人文观察','时政评论','自然观赏','生活品味'];

    React.useEffect(()=>{
        queryPosts().then((res)=>{
            console.log(res);
            setVideoSource(res.data||[]);
        })
    },[])
    React.useEffect(()=>{
        queryPosts({category:highlightedBtn}).then((res)=>{
            console.log(res);
            setVideoSource(res.data||[]);
        })
    },[highlightedBtn])
    return (

            <Drawer
                className="my-drawer"
                style={{
                    minHeight:windowHeight,
                    position: 'relative',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch'

                }}
                enableDragHandle={true}
                contentStyle={{ color: '#602020', textAlign: 'center',padding:24,width:'100%',backgroundColor:'white',display:'flex',flexDirection:'column'}}
                // dragHandleStyle={{width:0}}
                sidebar={
                        cateList.map(
                            (item,i)=>{
                                return<>
                                <CategoryButton
                                    highlighted={i===highlightedBtn}
                                    icon={<Comment style={{height:28,width:28}}/>}
                                    title={item}
                                    index={i}
                                    onClick={()=>{setHighlight(i);onOpenChange()}}
                                />
                                <Divider style={{margin:0,backgroundColor:'whitesmoke'}}/>
                                </>
                            }
                        )

                }
                open={isDrawerOpen}
                onOpenChange={onOpenChange}
            >
                <CateButton
                    onClick={()=>{toggleDrawer(true)
                    }}>
                    <ArrowDropDown/>
                    {cateList[highlightedBtn]}
                </CateButton>
                <ScrollBars
                    style={{width:'100%',marginTop:12}}
                    // onScroll={()=>{refreshSource();}}
                    autoHeight={true}
                    autoHeightMin={windowHeight-64}
                    renderTrackHorizontal={()=>(<div></div>)}
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    // ref={(comp)=>{videoBlock=comp}}
                >
                    <div
                        style={{
                            width:'100%',
                            display:'flex',
                            minHeight:windowHeight,
                            flexDirection:'column',
                            //backgroundColor:'#e0505090',
                            paddingRight:12,
                        }}
                    >
                        {videoSource.map((item)=>{
                            return (<PostCard key={item.id} data={item} isMine={props.user?.id===item.user.id} user={props.user}/>)
                        })}
                        {videoSource[0]?(null):(
                            <Card style={{width: '100%',height:80,marginTop:36,display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#D0D0D0'}} className="neighblog-card">
                                <text style={{fontSize:20,color:"#606060"}}>该分类下暂无内容</text>
                            </Card>
                        )}
                    </div>
                </ScrollBars>
                {/*<Link to={'/newPost'}>*/}
                {/*    <IconButton style={{position:'absolute',height:48,width:48,right:12,top:windowHeight-144,backgroundColor:'#E05050',boxShadow:'4px 4px 4px #303030A0',zIndex:10}}>*/}
                {/*        <Add style={{height:32,width:32,color:'whitesmoke'}}/>*/}
                {/*    </IconButton>*/}
                {/*</Link>*/}
            </Drawer>
    );
}

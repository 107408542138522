import React from "react";
import classNames from "classnames";
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import {Card} from "antd";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import {Comment,Favorite,Share,Add} from "@material-ui/icons";


import PostCard from "./components/PostCard";
import CategoryButton from "./components/CategoryButton";


import ScrollBars from 'react-custom-scrollbars';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";

import UseViewport from "../../utils/UseViewport";
import './components/PostCard.css';
import {Link} from "react-router-dom";
import {queryPosts} from "./Service";
import {generateIframe} from "./utils";


export default function VideoPage(props) {
    const CustomTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: 'white',
                fontSize:20,
            },
            '& label': {
                color: 'white',
            },
            '& .MuiOutlinedInput-root': {
                //backgroundColor:'#FFFFFFA0',
                '& fieldset': {
                    borderColor: 'white',
                },
                '&:hover fieldset': {
                    borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'white',
                },
            },
        },
    })(TextField);
    const videoBlock = React.useRef();
    const [videoSource,setVideoSource] = React.useState([]);
    React.useEffect(()=>{
        queryPosts().then((res)=>{
            console.log(res);
            setVideoSource(res.data||[]);
        })
    },[])

    const {height:windowHeight,width:windowWidth } = UseViewport();

    const [highlightedBtn,setHighlight] = React.useState(0);
    React.useEffect(()=>{
        queryPosts({category:highlightedBtn}).then((res)=>{
            console.log(res);
            setVideoSource(res.data||[]);
        })
    },[highlightedBtn])
    return (
        <div style={{padding:24,width:windowWidth-24,backgroundColor:'white'}}>

            <GridContainer  >
                <GridItem xs={2}/>
                <GridItem xs={2}>
                    <ScrollBars
                        style={{width:240}}
                        autoHeight={true}
                        autoHeightMin={windowHeight}
                        renderTrackHorizontal={()=>(<div></div>)}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        ref={videoBlock}
                    >
                        <div
                            style={{
                                width:'100%',
                                display:'flex',
                                minHeight:windowHeight,
                                flexDirection:'column',
                                //backgroundColor:'#00FFFF30',
                                //paddingRight:16,
                                paddingTop:64,
                                paddingBottom:16,
                            }}
                        >
                            {['全部','中华医药','中文教学','传统文化','艺术交流','人文观察','时政评论','自然观赏','生活品味'].map(
                                (item,i)=>{
                                    return <CategoryButton
                                        highlighted={i===highlightedBtn}
                                        icon={<Comment style={{height:28,width:28}}/>}
                                        title={item}
                                        index={i}
                                        onClick={()=>{setHighlight(i);console.log(i)}}
                                    />
                                }
                            )}
                        </div>
                    </ScrollBars>
                </GridItem>
                <GridItem xs={6}>
                    <div style={{display:"relative"}}>
                    <ScrollBars
                        style={{width:960}}
                       // onScroll={()=>{refreshSource();}}
                        autoHeight={true}
                        autoHeightMin={windowHeight}
                        renderTrackHorizontal={()=>(<div></div>)}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                    >
                        <div
                            style={{
                                width:'100%',
                                display:'flex',
                                minHeight:windowHeight,
                                flexDirection:'column',
                                //backgroundColor:'#e0505090',
                                paddingRight:24,
                                paddingTop:64,
                                paddingBottom:16,
                            }}
                        >
                            {videoSource.map((item)=>{
                                return (<PostCard key={item.id} data={item} isMine={props.user?.id===item.user.id} user={props.user}/>)
                            })}
                            {videoSource[0]?(null):(
                                <Card style={{width: '100%',height:80,marginTop:36,display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#D0D0D0'}} className="neighblog-card">
                                    <text style={{fontSize:20,color:"#606060"}}>该分类下暂无内容</text>
                                </Card>
                            )}
                        </div>
                    </ScrollBars>
                        <Link to={'/newPost'}>
                            <IconButton style={{position:'absolute',height:72,width:72,right:24,top:windowHeight-144,backgroundColor:'#E05050',boxShadow:'4px 4px 4px #303030A0',zIndex:10}}>
                                <Add style={{height:40,width:40,color:'whitesmoke'}}/>
                            </IconButton>
                        </Link>
                    </div>
                </GridItem>
                <GridItem xs={2}/>
            </GridContainer>
        </div>
    );
}

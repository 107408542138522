import React from "react";

export default function useViewport(){
    const [dimension, setDimension] = React.useState({
        height:window.innerHeight,
        width:window.innerWidth
    });
    React.useEffect(() => {
        const handleWindowResize = () => setDimension({
            height:window.innerHeight,
            width:window.innerWidth
        });
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return dimension;
};

export function login(body) {
    const requestBody = {
        username: body.username,
        password: body.password,
    };
    return new Promise(function (resolve, reject) {
        fetch("/api/login", {
            mode: "cors",
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(requestBody),
        })
            .then((res) => {
                fetch("/api/loginCallback", {
                    mode: "cors",
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                    },
                }).then((res)=>{
                    resolve(res.json());
                }).catch((e)=>{console.log('err::',e)})
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}


export function register(body) {
    const requestBody = {
        username: body.username,
        password: body.password,
    };
    return new Promise(function (resolve, reject) {
        fetch("/api/user", {
            mode: "cors",
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(requestBody),
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}


export function auth() {
    return new Promise(function (resolve, reject) {
        fetch("/api/userinfo", {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}
export function logout() {
    return new Promise(function (resolve, reject) {
        fetch("/api/logout", {
            mode: "cors",
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => {
                console.log("res::", res);
                resolve(res.json());
            })
            .catch((e) => {
                console.log("err::", e);
                reject(e);
            });
    });
}

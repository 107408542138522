import React from "react";
import UseViewport from "../../utils/UseViewport";
import { Upload, message} from 'antd';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InboxOutlined ,VideoCameraAddOutlined} from '@ant-design/icons';
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import {Paper,Button} from "@material-ui/core";
import './Override.css'
import {editPost} from "./Service";

import {resolveVideoUrl,generateIframe} from "./utils";

const { Dragger } = Upload;



const headerTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#602020',
        },
        secondary: {
            main: '#ffffff',
        },
    },
});
const SubmitBtn=(props)=>(
    <Paper
        elevation={5}
        onClick={()=>{props.onClick()}}
        style={{marginTop:24,marginBottom:12,width:240,height:64,backgroundColor:'#F0F0F0',color:'#E05050',borderRadius:0,display:'flex',alignItems:'center',justifyContent:'center'}}>
        <a style={{color:'#E05050',fontWeight:'700',fontSize:22}}>{props.text}</a>
    </Paper>
)


export default function EditPostPage(props){

    const {data} = props.location.state;
    const {height:windowHeight,width:windowWidth } = UseViewport();
    const [previewData,setPreviewData] = React.useState({
        videoSource:data.videoSource,
        videoUrl:data.videoUrl,
        height:720,
        width:360,
    });
    const [category,setCategory] = React.useState(data.category);
    React.useEffect(()=>{
        document.getElementById('videoUrl').value=data.originalUrl;
        document.getElementById('title').value=data.title;
        document.getElementById('body').value=data.body;
    },[])

    return (
        <ThemeProvider theme={headerTheme}>
            <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div style={{width:0.64*windowWidth,minHeight:windowHeight-72,backgroundColor:'#602020',display:'flex',flexDirection:'column',paddingTop:96,paddingLeft:48,paddingRight:48}}>
                    <text style={{fontSize:40,fontWeight:'700',color:'whitesmoke',marginTop:48,textShadow:'1px 1px 1px #30303090'}}>
                        编辑帖子
                    </text>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'space-between',marginTop:48}}>
                        <div style={{display:'flex',flexGrow:1,marginRight:48,flexDirection:'column'}}>
                            <text style={{fontSize:20,color:'whitesmoke',marginBottom:12}}>插入视频链接</text>
                            <div style={{display:'flex',flexDirection:'row',marginBottom:12}}>
                                <TextField id="videoUrl" label="插入视频链接" variant="filled" style={{backgroundColor:'white',flexGrow:1}} />
                                <Button
                                    style={{width:96,height:55.5,margin:0,backgroundColor:'#E05050',color:'whitesmoke',borderRadius:0}}
                                    onClick={()=>{
                                        const url = document.getElementById('videoUrl').value;
                                        const videoData = resolveVideoUrl(url);
                                        if(videoData){
                                            setPreviewData(videoData)
                                        }
                                    }}
                                >解析地址</Button>
                            </div>
                            <text style={{fontSize:20,color:'whitesmoke',marginBottom:12}}>自行上传视频(暂不支持)</text>
                            <Dragger {...props} style={{backgroundColor:'#F0F0F0',marginBottom:12}} disabled={true}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined style={{color:'#602020'}} />
                                </p>
                                <p className="ant-upload-text">点击或拖拽视频至此处上传</p>
                                <p className="ant-upload-hint">
                                    支持.mp4 .avi .flv格式，文件大小不超过100M，长度不超过5分钟
                                </p>
                            </Dragger>
                            <text style={{fontSize:20,color:'whitesmoke',marginBottom:12}}>标题</text>
                            <TextField id="title" label="输入帖子标题" variant="filled" style={{backgroundColor:'white',marginBottom:12}} />
                            <text style={{fontSize:20,color:'whitesmoke',marginBottom:12}}>正文</text>
                            <TextField id="body" label="输入正文" variant="filled" multiline={true} rows={3} style={{backgroundColor:'white',marginBottom:12}} />
                            <text style={{fontSize:20,color:'whitesmoke',marginBottom:12}}>选择分类</text>
                            <Select
                                id="category"
                                displayEmpty
                                placeholder={'选择分类'}
                                style={{backgroundColor:'white',height:48}}
                                value={category}
                                onChange={(event)=>{setCategory(event.target.value)}}
                            >
                                <InputLabel id="category_label">选择分类</InputLabel>
                                {['无分类','中华医药','中文教学','传统文化','艺术交流','人文观察','时政评论','自然观赏','生活品味'].map((item,index)=>(
                                    <MenuItem value={index}>{item}</MenuItem>
                                ))}
                            </Select>
                            <SubmitBtn onClick={async ()=>{
                                if(previewData){
                                    const res = await editPost({
                                        originalUrl:document.getElementById('videoUrl').value,
                                        title:document.getElementById('title').value,
                                        body:document.getElementById('body').value,
                                        category:category,
                                        videoUrl:previewData.videoUrl,
                                        videoSource:previewData.videoSource,
                                    },data.id);
                                    console.log(res);
                                    if(res.success){
                                        alert('编辑成功');
                                        window.location.href='/video';
                                    }
                                }else {
                                    alert('请先插入视频')
                                }
                            }} text={'提交'}/>
                        </div>
                        {previewData?(
                            generateIframe(previewData)
                        ):(
                            <div style={{display:'flex',flexDirection:'column',width:360,minWidth:360,height:720,backgroundColor:'#D0D0D0',alignItems:'center',justifyContent:'center',borderRadius:2,boxShadow:'2px 2px 2px #000000a0'}}>
                                <VideoCameraAddOutlined  style={{ fontSize:64,color:'#909090'}}/>
                                <text style={{fontSize:24,color:'#909090',marginTop:12,fontWeight:'700'}}>{`预览\xa0`}</text>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </ThemeProvider>
    )

}


import React from "react";

import { Parallax ,Background} from 'react-parallax';
import {Paper} from "@material-ui/core";
import {Button} from "antd";
import EntryAnim from "../../utils/EntryAnim";
import UseViewport from "../../utils/UseViewport";
import '../general.css';
import useViewport from "../../utils/UseViewport";
import TextField from '@material-ui/core/TextField';


const dashboardRoutes = [];

const bg1 = require('assets/img/newBG3.jpg');
const bg2 = require('assets/img/newBG6.jpg');
const infoImage = require('assets/img/feedback_box_3.jpg');
const sloganImage = require("assets/img/slogan.png");
const logoImage = require("assets/img/logo.jpg");
const block2Image= require("assets/img/su.jpg");
const block3Image= require("assets/img/su.jpg");



const styles = {
    //fontFamily: "sans-serif",
    //textAlign: "center",
    //backgroundColor:'#600000',
};
const blockStyle = {
    width:'100%',
    height:'100%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',

};

const ContactBtn=(props)=>(
    <Paper
        elevation={5}
        onClick={()=>{props.onClick()}}
        style={{marginTop:24,marginBottom:12,width:180,height:48,backgroundColor:'#E05050',borderRadius:0,display:'flex',alignItems:'center',justifyContent:'center'}}>
        <a style={{color:'whitesmoke',fontWeight:'600',fontSize:18}}>{props.text}</a>
    </Paper>
)


export default function LandingPage(props) {
    const {width:windowWidth,height:windowHeight} = useViewport();
    return (
        <div style={styles}>
            <Parallax bgImage={bg1} bgImageStyle={{width:windowWidth,height:'auto',minHeight:360,marginTop:64,objectFit:'cover'}} strength={500}>
                   <div style={{
                       height:320,
                       width:'100%',
                       display:'flex',
                       flexDirection:'column',
                       backgroundColor:'#FFFFFF20',
                       alignItems:'center',
                       justifyContent:'center',
                       marginTop:64
                   }}>
                       <div style={{
                           display:'flex',
                           width:'100%',
                           height:160,
                           alignItems:'center',
                           backgroundColor:'#00000060'
                       }}>
                           <div style={{flexGrow:1}}/>
                           <EntryAnim
                           type={'fadeInLeft'}
                           duration={'1.6s'}
                           partialVisibility={false}
                           placeholder={<div style={{height:2,width:2}}/>}
                           >
                           <div style={{display:'flex',flexDirection:'column',color:'whitesmoke'}}>
                               <text style={{fontSize:40,fontWeight:'700',lineHeight:1.6,letterSpacing:1}}>联系我们</text>
                               <text style={{fontSize:20,fontWeight:'500'}}>澳大利亚中华电视·联系我们</text>
                           </div>
                           </EntryAnim>
                           <div style={{flexGrow:3}}/>
                       </div>
                   </div>
            </Parallax>
            <Parallax bgImage={bg2} strength={500} blur={{ min: 3, max: -3 }}>
                <div style={{ height: 720 ,backgroundColor:'#F0F0F0A0'}}>
                    <div style={blockStyle}>
                        <div style={{flexGrow:1}}/>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <EntryAnim
                                type={'fadeInUp'}
                                duration={'1.8s'}
                                partialVisibility={true}
                                //placeholder={<div style={{height:2,width:2}}/>}
                            >
                                    <Paper elevation={5} style={{backgroundColor:"whitesmoke",width:720,height:480,borderRadius:0,padding:48,display:'flex',flexDirection:'column',flexGrow:1,position:'relative'}}>
                                        <img src={logoImage} width={128} height={'auto'} style={{objectFit:'cover',position:'absolute',top:12,right:12}} />
                                        <img src={infoImage} width={224} height={'auto'} style={{objectFit:'cover',position:'absolute',bottom:0,right:0}} />
                                        <text style={{fontWeight:'700',fontSize:32}}>澳大利亚中华电视</text>
                                            <div style={{height:8,width:48,backgroundColor:'#E05050',marginTop:10,marginBottom:8}}/>
                                            <div style={{padding:12,paddingTop:72,paddingBottom:72,display:'flex',flexDirection:'column',flexGrow:1,justifyContent:'space-between'}}>
                                                <div>
                                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                    <svg preserveAspectRatio="xMidYMid meet" data-bbox="42 20.5 116 159" viewBox="42 20.5 116 159" height="44" width="44" fill={'#E05050'} xmlns="http://www.w3.org/2000/svg" data-type="color" role="img">
                                                        <g>
                                                            <path d="M99.999 179.5l-4.053-4.877C93.746 171.972 42 109.349 42 78.612 42 46.569 68.018 20.5 99.999 20.5 131.985 20.5 158 46.569 158 78.612c0 30.738-51.743 93.361-53.948 96.011l-4.053 4.877zm0-148.434c-26.166 0-47.455 21.328-47.455 47.545 0 20.481 31.206 63.869 47.455 84.264 16.252-20.394 47.458-63.783 47.458-84.264-.001-26.217-21.289-47.545-47.458-47.545z" fill={'#E05050'}></path>
                                                            <path d="M99.999 102.075c-12.911 0-23.419-10.525-23.419-23.464s10.507-23.466 23.419-23.466c12.914 0 23.421 10.527 23.421 23.466 0 12.939-10.507 23.464-23.421 23.464zm0-36.364c-7.097 0-12.872 5.786-12.872 12.9 0 7.112 5.775 12.899 12.872 12.899 7.1 0 12.878-5.787 12.878-12.899-.001-7.114-5.779-12.9-12.878-12.9z" fill={'#E05050'}></path>
                                                        </g>
                                                    </svg>
                                                        <text style={{fontSize:22,lineHeight:1.5,marginLeft:12}}>地址：…………</text>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                        <svg preserveAspectRatio="xMidYMid meet" data-bbox="42 20.5 116 159" viewBox="42 20.5 116 159" height="44" width="44" fill={'#E05050'} xmlns="http://www.w3.org/2000/svg" data-type="color" role="img">
                                                            <g>
                                                                <path d="M57.671 34.013c2.802 0 5.998 1.02 8.46 4.693 6.633 9.883 13.03 19.193 16.572 25.532 1.199 2.151 2.363 8.239-1.553 12.646-3.381 3.798-6.364 4.73-8.165 7.504-1.235 1.912-2.646 6.184-.325 11.096 2.776 5.867 6.162 10.685 13.592 18.234 7.547 7.421 12.364 10.804 18.242 13.581 1.918.905 3.742 1.241 5.363 1.241 2.532 0 4.574-.816 5.735-1.566 2.776-1.799 3.71-4.78 7.511-8.161 2.292-2.033 5.034-2.696 7.443-2.696 2.231 0 4.177.57 5.207 1.148 6.342 3.536 15.657 9.931 25.549 16.558 6.938 4.649 4.408 11.913 3.431 14.196-.982 2.284-6.004 9.841-16.805 15.491-2.972 1.553-6.893 2.476-11.648 2.476-14.968 0-38.155-9.16-65.66-36.651-36.237-36.217-40.635-64.946-34.188-77.26 5.651-10.798 13.213-15.818 15.5-16.795 1.074-.462 3.25-1.267 5.739-1.267zm0-10.513c-4.111 0-7.658 1.158-9.908 2.13-4.45 1.896-13.862 8.598-20.651 21.572-8.484 16.206-4.132 49.387 36.071 89.566 34.585 34.568 60.642 39.731 73.097 39.731 6.301 0 11.86-1.237 16.522-3.674 12.956-6.777 19.673-16.176 21.598-20.661 4.489-10.497 1.645-21.118-7.239-27.075-1.525-1.02-3.036-2.035-4.522-3.034-8.235-5.536-16.013-10.766-21.758-13.972-2.808-1.57-6.577-2.477-10.331-2.479-5.495 0-10.484 1.848-14.427 5.348-2.618 2.327-4.251 4.482-5.442 6.054-.246.325-.552.73-.788 1.02h-.033c-.247 0-.54-.078-.87-.234-4.366-2.062-8.274-4.609-15.298-11.51-6.908-7.024-9.456-10.928-11.519-15.292-.191-.403-.24-.699-.237-.9.29-.235.698-.543 1.025-.79 1.574-1.191 3.729-2.82 6.053-5.43 6.654-7.492 6.45-18.339 2.884-24.745-3.234-5.787-8.505-13.615-14.088-21.903-.968-1.439-1.953-2.899-2.942-4.374C70.824 26.82 64.717 23.5 57.671 23.5z" fill="#E05050"></path>
                                                            </g>
                                                        </svg>
                                                        <text style={{fontSize:22,lineHeight:1.5,marginLeft:12}}>联系电话：(+61) 4XX XXX XXX</text>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                        <svg preserveAspectRatio="xMidYMid meet" data-bbox="42 20.5 116 159" viewBox="42 20.5 116 159" height="44" width="44" fill={'#E05050'} xmlns="http://www.w3.org/2000/svg" data-type="color" role="img">
                                                            <g>
                                                                <path d="M173.694 43H26.306A6.307 6.307 0 0 0 20 49.307v101.385a6.307 6.307 0 0 0 6.306 6.307h147.388a6.307 6.307 0 0 0 6.306-6.307V49.307A6.307 6.307 0 0 0 173.694 43zm-12.977 12.615l-58.092 44.653L39.25 55.615h121.467zm-128.105 88.77V66.368l66.543 46.886a6.3 6.3 0 0 0 7.474-.155l60.759-46.702v77.989H32.612z"></path>
                                                            </g>
                                                        </svg>
                                                        <text style={{fontSize:22,lineHeight:1.5,marginLeft:12}}>联系邮箱：zhonghuatv@outlook.com</text>
                                                    </div>
                                                </div>
                                            </div>
                                    </Paper>
                            </EntryAnim>
                        </div>
                        <div style={{width:48}}/>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <EntryAnim
                                type={'fadeInUp'}
                                duration={'2s'}
                                partialVisibility={true}
                                //placeholder={<div style={{height:2,width:2}}/>}
                            >
                                <Paper elevation={5} style={{backgroundColor:"whitesmoke",width:720,height:480,borderRadius:0,padding:48,display:'flex',flexDirection:'column',flexGrow:1}}>
                                    <text style={{fontWeight:'700',fontSize:32}}>联系我们</text>
                                    <div style={{height:8,width:48,backgroundColor:'#E05050',marginTop:10,marginBottom:8}}/>
                                    <div style={{padding:12,paddingTop:24,paddingBottom:24,display:'flex',flexDirection:'column',flexGrow:1,justifyContent:'space-between'}}>
                                        <TextField id="name" label="联系人姓名" variant="outlined" />
                                        <TextField style={{marginTop:12}} id="email" label="联系邮箱" variant="outlined" />
                                        <TextField style={{marginTop:12}} id="note" multiline={true} rows={3} label="您的留言..." variant="outlined" />
                                        <ContactBtn text={'提交'} onClick={()=>{}} />
                                    </div>
                                </Paper>
                            </EntryAnim>
                        </div>
                        <div style={{flexGrow:1}}/>
                    </div>
                </div>
            </Parallax>
        </div>
    );
}
